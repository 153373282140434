@mixin font-cust($font-family, $file-path) {
  @font-face {
    font-family: $font-family;
    src: url('#{$file-path}.woff') format('woff');
    font-display: swap;
  }
}

////
@include font-cust(Maax, './../../assets/fonts/Maax');
@include font-cust(Maax-Medium, './../../assets/fonts/Maax-Medium');
@include font-cust(Maax-Bold, './../../assets/fonts/Maax-Bold');

@mixin Font-Regular {
  font-family: 'Maax';
  font-weight: normal;
}
@mixin Font-Medium {
  font-family: 'Maax-Medium';
  font-weight: normal;
}
@mixin Font-Bold {
  font-family: 'Maax-Bold';
  font-weight: normal;
}
