.tabs {
  display: flex;
  border: 1px solid var(--color-border);
  margin-bottom: 53px;
  position: sticky;
  top: 0;
  z-index: 100;
  @include mq('xxl', 'max') {
    margin-bottom: 40px;
  }
  @include mq('lg', 'max') {
    margin-bottom: 20px;
  }
  @include mq('sm', 'max') {
    flex-direction: column;
    position: relative;
  }
  > div {
    flex: 1;
    color: var(--color-primary);
    @include Font-Medium;
    font-size: 34px;
    line-height: normal;
    cursor: pointer;
    padding: 26px 20px 24px;
    text-align: center;
    transition: background-color 0.3s;
    background-color: var(--color-white);
    @include mq('xxl', 'max') {
      font-size: 24px;
      padding: 20px;
    }
    @include mq('lg', 'max') {
      font-size: 21px;
      padding: 14px 15px 13px;
    }
    @include mq('md', 'max') {
      font-size: 18px;
    }
    @include mq('sm', 'max') {
      font-size: 22px;
      padding: 13px 15px 11px;
    }
    &:not(:first-child) {
      border-left: 1px solid var(--color-border);
      @include mq('sm', 'max') {
        border-left: 0;
        border-top: 1px solid var(--color-border);
      }
    }

    &.active {
      color: var(--color-white);
      background-color: var(--color-sec);
    }
  }
}
