//==============* Breakpoint mixin *=======================

$breakpoints: (
  'xs': 576px,
  'sm': 768px,
  'md': 992px,
  'lg': 1200px,
  'xl': 1400px,
  'xxl': 1600px,
  'xlarge': 1920px,
);
@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}
//.site-header {
//  @include mq('t-992', 'max') {
//    padding: 10px;
//  }
//}
//
