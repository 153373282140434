.container {
  width: 100%;
  margin: 0 auto;
  @include mq('xs', 'min') {
    max-width: 540px;
  }
  @include mq('sm', 'min') {
    max-width: 720px;
  }
  @include mq('md', 'min') {
    // max-width: 960px;
    max-width: 912px;
  }
  @include mq('lg', 'min') {
    max-width: 1260px;
  }
  @include mq('xlarge', 'min') {
    max-width: 1706px;
  }
  // @include mq('xl', 'min') {
  //   max-width: 1260;
  // }
  @include mq('sm', 'max') {
    padding: 0 21px;
  }
}
