.header-banner {
  background-image: url('./../../assets/images/header-bg.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  // min-height: 553px;
  // min-height: 68.2vh;
  position: relative;
  padding: 118px 0;
  overflow: hidden;
  color: #ffffff;
  margin-bottom: 90px;
  @include mq('xxl', 'max') {
    // min-height: 55vh;
    padding: 89px 0;
  }
  @include mq('lg', 'max') {
    // min-height: 51.2vh;
    padding: 64px 0;
    margin-bottom: 65px;
  }
  @include mq('md', 'max') {
    padding: 48px 0;
    // min-height: 28.9vh;
    margin-bottom: 40px;
  }
  @include mq('sm', 'max') {
    padding: 29px 0 61px;
  }
  &:after {
    position: absolute;
    height: 100%;
    width: 100%;
    content: '';
    background: rgba(0, 0, 0, 0.54);
    top: 0;
  }
  .container {
    position: relative;
    z-index: 100;
  }
  .logo {
    min-width: 242px;
    @include mq('xxl', 'max') {
      min-width: auto;
    }
    @include mq('lg', 'max') {
      max-width: 127px;
    }
    @include mq('sm', 'max') {
      max-width: 84px;
    }
  }
  h1 {
    font-size: 53px;
    line-height: normal;
    // margin: 140px 0 57px;
    margin: 89px 0 15px;
    @include Font-Bold;
    @include mq('xxl', 'max') {
      // margin: 97px 0 26px;
      margin: 85px 0 15px;
    }
    @include mq('lg', 'max') {
      // margin: 60px 0 0px;
    }
    @media (max-width: 1024px) {
      margin: 65px 0 15px;
    }
    @include mq('md', 'max') {
      font-size: 30px;
      margin: 40px 0 8px;
    }
    @include mq('sm', 'max') {
      font-size: 30px;
      margin: 40px 0 15px;
    }
  }
  p {
    font-size: 25px;
    line-height: 35px;
    margin: 0;
    max-width: 50.5%;
    @include mq('lg', 'max') {
      max-width: 70.5%;
    }
    @include mq('md', 'max') {
      font-size: 14px;
      line-height: 18px;
    }
    @include mq('sm', 'max') {
      font-size: 12px;
      line-height: 18px;
      max-width: 100%;
    }
  }
  .head-curve {
    position: absolute;
    width: 100%;
    bottom: -20px;
    display: flex;
    z-index: 1;
    display: none !important;
    @include mq('lg', 'max') {
      bottom: -13px;
    }
    @include mq('md', 'max') {
      bottom: -10px;
    }
    @include mq('sm', 'max') {
      bottom: -4px;
    }
  }
}
