:root {
  --color-white: #ffffff;
  --color-black: #000000;

  --color-primary: #5386cc;
  --color-sec: #2b56a3;
  --color-gray: #cacacd;
  --color-graydark: #979797;
  --color-text: #2c2a39;
  --color-border: #ebebeb;
}
