// :root {
//   font-size: 100%;
// }

body {
  -webkit-overflow-scrolling: touch;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  @include Font-Regular;
  .home-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    main {
      flex: 1;
      > .container {
        padding: 0 34px;
      }
    }
  }
}
a {
  text-decoration: none;
  cursor: pointer;
  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
}
img {
  max-width: 100%;
}
.img-fullwidth {
  width: 100%;
}

.img-maxwidth {
  max-width: 100%;
}

///////

.lds-ring {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  position: absolute;
  > div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    border: 4px solid #2c2a39;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #2c2a39 transparent transparent transparent;
    &:nth-child(1) {
      animation-delay: -0.45s;
    }
    &:nth-child(2) {
      animation-delay: -0.3s;
    }
    &:nth-child(3) {
      animation-delay: -0.15s;
    }
  }
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
