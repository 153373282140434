.custom-radio {
  font-family: 'max-medium';
  color: #2c2a39;
  border-right: 1px solid #979797;
  // border-left: 0;
  position: relative;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: 1px;
    background: #979797;
    top: 0;
    left: 0;
  }
  &:before {
    content: '';
    position: absolute;
    width: calc(100% + 2px);
    height: 1px;
    background: #979797;
    bottom: -1px;
    left: -1px;
    z-index: 1;
  }

  [type='radio']:checked,
  [type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type='radio']:checked + label,
  [type='radio']:not(:checked) + label {
    position: relative;
    cursor: pointer;
    // line-height: 20px;
    display: flex;
    align-items: center;
    color: #cacacd;
    min-height: 60px;
    padding: 4px 41px 4px;
    font-size: 22px;
    @include Font-Medium;
    @include mq('xxl', 'max') {
      font-size: 18px;
      min-height: 47px;
      padding: 4px 30px 0;
    }
    @include mq('lg', 'max') {
      font-size: 16px;
      min-height: 32px;
      padding: 4px 19px 0;
    }
    @include mq('md', 'max') {
      font-size: 12px;
      min-height: 26px;
      padding: 4px 15px 0;
      min-width: 45px;
    }
    @include mq('sm', 'max') {
      min-width: 50px;
      min-height: 30px;
    }
  }

  [type='radio'] + label span {
    position: relative;
    z-index: 1;
    width: 100%;
    text-align: center;
  }

  [type='radio']:checked + label span {
    color: #fff;
  }

  [type='radio']:checked + label:before,
  [type='radio']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    // height: 60px;
    height: 100%;
    background: var(--color-white);
    // @include mq('xxl', 'max') {
    //   height: 45px;
    // }
    // @include mq('lg', 'max') {
    //   height: 32px;
    // }
  }

  [type='radio']:checked + label:after,
  [type='radio']:checked + label:before,
  [type='radio']:not(:checked) + label:after {
    content: '';
    top: 0px;
    width: 100%;
    // height: 60px;
    height: 100%;
    background: var(--color-primary);
    left: 0px;
    position: absolute;
    transition: all 0.2s ease;
    // @include mq('xxl', 'max') {
    //   height: 45px;
    // }
    // @include mq('lg', 'max') {
    //   height: 32px;
    // }
  }

  [type='radio']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  [type='radio']:checked + label:after {
    opacity: 1;
    transform: scale(1);
  }

  [type='radio']:checked:after {
    content: '';
    width: 100%;
    height: 60px;
    background: var(--color-primary);
    left: 0;
    position: absolute;
    transition: all 0.2s ease;
  }
  &.radio-enable {
    [type='radio']:not(:checked) + label {
      color: #6d6d6e !important;
    }
  }
}
