.select-wrapper {
  width: 100%;
  @include mq('lg', 'max') {
    padding-left: 24px;
  }
  @include mq('md', 'max') {
    padding-left: 18px;
  }
  @include mq('sm', 'max') {
    padding-left: 0;
  }
}
.custom-dropdown-wrapper {
  width: 100%;
}
.cust-select {
  max-width: 453px;
  width: 100%;
  outline: none;
  @include mq('xxl', 'max') {
    max-width: 340px;
  }
  @include mq('lg', 'max') {
    max-width: 242px;
  }
  @include mq('md', 'max') {
    max-width: 180px;
  }
  @include mq('sm', 'max') {
    max-width: 100%;
  }
  div[class*='control'] {
    // width: 453px;
    width: 100%;
    min-height: 61px;
    color: var(--color-text);
    padding: 11px 16px 9px 20px;
    border-radius: 6px;
    border: solid 1px var(--color-graydark);
    @include mq('xxl', 'max') {
      min-height: 46px;
    }
    @include mq('lg', 'max') {
      min-height: 32px;
      padding: 4px 16px 3px 14px;
    }
    @include mq('md', 'max') {
      min-height: auto;
      padding: 1px 7px 0px 12px;
    }
    @include mq('sm', 'max') {
      padding: 6px 10px 4px 15px;
    }

    div:nth-child(1) {
      padding: 0;
      div[class*='placeholder'] {
        color: var(--color-gray);
        @include Font-Medium;
        font-size: 22px;
        @include mq('xxl', 'max') {
          font-size: 18px;
        }
        @include mq('lg', 'max') {
          font-size: 16px;
        }
        @include mq('md', 'max') {
          font-size: 12px;
        }
      }
      div[class*='singleValue'] {
        margin: 0;
        font-size: 22px;
        color: var(--color-gray);
        @include Font-Medium;
        @include mq('xxl', 'max') {
          font-size: 18px;
        }
        @include mq('lg', 'max') {
          font-size: 16px;
        }
        @include mq('md', 'max') {
          font-size: 12px;
        }
      }
    }

    div:nth-child(2) {
      span[class*='indicatorSeparator'] {
        display: none;
      }

      div[class*='indicatorContainer'] {
        background-image: url('./../../assets/images/arrow_down.svg');
        background-size: 12px 7px;
        background-repeat: no-repeat;
        background-position: 50%;
        transition: all 0.2s;
        @include mq('md', 'max') {
          background-size: 7px 4px;
        }
        @include mq('sm', 'max') {
          background-size: 12px 7px;
        }
        svg {
          display: none;
        }
      }
    }
  }

  div[class*='menu'] {
    top: calc(100% + 8px) !important;
    margin: 0;
    border-radius: 6px;
    border: 1px solid var(--color-graydark);
    background-color: var(--color-white);
    // width: 453px;
    width: 100%;
    z-index: 5;
    overflow: scroll;
    @include mq('lg', 'max') {
      top: calc(100% + 4px) !important;
    }
    > div {
      padding: 0;
      div[class*='option'] {
        color: var(--color-gray);
        @include Font-Medium;
        font-size: 18px;
        padding: 16px 20px 14px;
        background-color: transparent;
        cursor: pointer;
        border-bottom: 1px solid rgba(151, 151, 151, 0.3);
        transition: all 0.2s;
        @include mq('lg', 'max') {
          font-size: 16px;
          padding: 9px 14px 7px;
        }
        @include mq('md', 'max') {
          font-size: 12px;
          padding: 8px 12px 6px;
        }
        @include mq('sm', 'max') {
          line-height: 8.5px;
          padding: 14px 15px 5px;
          min-height: 36px;
        }
        &:hover {
          background: var(--color-border);
          color: var(--color-text);
        }
      }
    }
  }

  &.open {
    div:nth-child(2) {
      div[class*='indicatorContainer'] {
        transform: rotate(180deg);
      }
    }
  }
}
