.footer-wrap {
  background-image: url('./../../assets/images/bottom-curve-bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  width: 100%;
  position: relative;
  display: flex;
  padding: 68px 0 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 120px;
  @include mq('xxl', 'max') {
    padding: 50px 0 33px;
  }
  @include mq('lg', 'max') {
    padding: 30px 0 28px;
  }
  @include mq('md', 'max') {
    margin-top: 80px;
    padding: 26px 0 17px;
  }
  @include mq('sm', 'max') {
    padding: 12px 0 11px;
  }
  .foot-curve {
    position: absolute;
    width: 100%;
    top: -26px;
    display: flex;
    @include mq('md', 'max') {
      top: -14px;
    }
    @include mq('sm', 'max') {
      top: -7px;
    }
  }
  .foot-logo {
    img {
      min-width: 239px;
      @include mq('xxl', 'max') {
        min-width: auto;
      }
      @include mq('lg', 'max') {
        max-width: 127px;
      }
      @include mq('md', 'max') {
        max-width: 96px;
      }
      @include mq('sm', 'max') {
        max-width: 74px;
      }
    }
  }

  .scroll-to-top {
    position: fixed;
    right: 8px;
    bottom: 8px;
    z-index: 100;
    @include mq('sm', 'min') {
      display: none;
    }
    button {
      display: inline-flex;
      padding: 0;
      margin: 0;
      background: transparent;
      border: 0;
      img {
      }
    }
  }
}
