.tab-content-wrapper {
  display: flex;
  align-items: flex-start;
  @include mq('sm', 'max') {
    flex-direction: column;
  }
  .mo-img-price-block {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    display: flex;
    flex-direction: column;
    padding: 21px 38px 20px;
    // align-items: center;
    background-color: var(--color-white);
    box-shadow: 0 1px 18px 0 rgba(0, 0, 0, 0.2);
    border: solid 1px var(--color-border);
    height: 100px;
    overflow: scroll;
    @include mq('sm', 'min') {
      display: none;
    }
    > div {
      display: flex;
      align-items: center;
      &:not(:last-child) {
        padding-bottom: 15px;
      }
    }
    .img-wrapper {
      margin-right: 10px;
      img {
        // max-width: 69px;
        max-height: 50px;
      }
    }
    .lbl-title {
      font-size: 14px;
      color: var(--color-primary);
      @include Font-Medium;
      margin-right: 8px;
    }
    .details-text {
      font-size: 12px;
      color: var(--color-text);
      @include Font-Medium;
    }
    .mo-content-block {
      > div {
        display: flex;
      }
      .price-wrapper {
      }
      .modal-wrapper {
        .lbl-title {
          margin-top: 5px;
        }
        .details-text {
          margin-top: 5px;
        }
      }
    }
  }
  .image-price-count {
    flex: 0 0 32.8%;
    max-width: 32.8%;
    // order: 2;
    @include mq('sm', 'max') {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .count {
      margin-bottom: 15px;
      // font-size: 18px;
      font-size: 28px;
      line-height: normal;
      @include Font-Medium;
      @include mq('xxl', 'max') {
        font-size: 20px;
      }
      @include mq('md', 'max') {
        font-size: 14px;
      }

      + .img-price-block {
        width: 100%;
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
  }
  .img-price-block {
    // border: 1px solid var(--color-border);
    // border-bottom: 0;
    flex: 0 0 32.8%;
    max-width: 32.8%;
    overflow-y: scroll;
    min-height: 334px;
    @include mq('sm', 'max') {
      flex: 1;
      max-width: 100%;
      margin-bottom: 11px;
      min-height: auto;
    }
    > div {
      display: flex;
      flex-direction: column;
      border: 1px solid var(--color-border);
      border-bottom: 0;
      &:not(:last-child) {
        margin-bottom: 10px;
      }
      > div {
        display: flex;
        align-items: center;
        padding: 30px 45px 18px;
        min-height: 121px;
        @include mq('xxl', 'max') {
          min-height: 91px;
          padding: 20px 45px 8px;
        }
        @include mq('lg', 'max') {
          padding: 14px 27px 2px 31px;
          min-height: auto;
        }
        @include mq('md', 'max') {
          padding: 10px 6px 0;
          justify-content: space-between;
        }
        @include mq('sm', 'max') {
          padding: 20px 22px 12px;
        }
      }
      .bounce {
        display: none;
      }
      &:nth-child(1) {
        .bounce {
          display: flex;
        }
      }
    }

    .lbl-title {
      font-size: 28px;
      line-height: normal;
      color: var(--color-primary);
      @include Font-Medium;
      margin-right: 41px;
      margin-bottom: 12px;
      @include mq('xxl', 'max') {
        font-size: 20px;
        margin-right: 25px;
      }
      @include mq('md', 'max') {
        font-size: 14px;
      }
      // @include mq('lg', 'max') {
      //   margin-bottom: 12px;
      // }
      @include mq('md', 'max') {
        margin-bottom: 10px;
        margin-right: 10px;
      }
    }
    .details-text {
      color: var(--color-text);
      @include Font-Medium;
      font-size: 28px;
      margin-bottom: 12px;
      @include mq('xxl', 'max') {
        font-size: 18px;
      }
      // @include mq('lg', 'max') {
      //   margin-bottom: 12px;
      // }
      @include mq('md', 'max') {
        margin-bottom: 10px;
        font-size: 12px;
      }
    }
    .img-wrapper {
      border-bottom: 1px solid var(--color-border);
      min-height: 404px;
      align-items: center;
      justify-content: center;
      padding: 14.5px 18px;
      position: relative;
      @include mq('xxl', 'max') {
        min-height: 303px;
      }
      @include mq('lg', 'max') {
        // min-height: auto;
        // padding: 41px 10px 10px;
        padding: 10px;
      }
      @include mq('md', 'max') {
        // padding: 30px 8px 10px;
      }
      @include mq('sm', 'max') {
        padding: 20px 12px;
        min-height: 200px;
      }
      img {
        max-height: 303px;
      }
      .down-animated-arrow {
        width: 25px;
        height: 25px;
      }
      .bounce {
        animation: bounce 2s infinite;
        position: absolute;
        right: 15px;
        bottom: 20px;
      }

      @keyframes bounce {
        0%,
        20%,
        50%,
        80%,
        100% {
          transform: translateY(0);
        }
        40% {
          transform: translateY(-20px);
        }
        60% {
          transform: translateY(-10px);
        }
      }
    }
    .price-wrapper {
      border-bottom: 1px solid var(--color-border);
      .details-text {
        font-size: 36px;
        line-height: 45px;
        @include Font-Bold;
        @include mq('xxl', 'max') {
          font-size: 34px;
        }
        @include mq('lg', 'max') {
          font-size: 28px;
        }
        @include mq('md', 'max') {
          font-size: 24px;
          line-height: 1;
        }
      }
    }
    .modal-wrapper {
      border-bottom: 1px solid var(--color-border);
      flex-wrap: wrap;
      // @include mq('lg', 'max') {
      // flex-direction: column;
      // align-items: flex-start;
      // justify-content: center;
      // flex-wrap: wrap;
      // }
      // .lbl-title {
      //   @include mq('lg', 'max') {
      //     margin-bottom: 12px;
      //   }
      // }
    }
    .content-wrapper {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid var(--color-border);
      .lbl-title {
        margin-right: 0;
        display: flex;
        width: 100%;
      }
      .lbl-error-title {
        margin-right: 0;
        display: flex;
        width: 100%;
        color: var(--color-graydark);
      }
      p {
        font-size: 24px;
        line-height: 1.3;
        margin-bottom: 12px;
        @include mq('xxl', 'max') {
          font-size: 18px;
        }
        // @include mq('lg', 'max') {
        //   margin-bottom: 12px;
        // }
        @include mq('md', 'max') {
          margin-bottom: 10px;
          font-size: 12px;
        }
        @include mq('sm', 'max') {
          font-size: 14px;
        }
      }
    }
  }

  .content-block {
    flex: 0 0 67.2%;
    max-width: 67.2%;
    flex: 1;
    // margin-left: 22px;
    margin-right: 22px;
    @include mq('sm', 'max') {
      flex: 1;
      max-width: 100%;
      margin-right: 0px;
      width: 100%;
    }

    .form-wrapper {
      border: 1px solid var(--color-border);
      border-bottom: 0;
      .lbl-title {
        font-size: 24px;
        @include Font-Medium;
        color: var(--color-primary);
        padding-right: 23px;
        min-width: 390px;
        max-width: 390px;
        @include mq('xxl', 'max') {
          font-size: 20px;
          min-width: 310px;
          max-width: 310px;
        }
        @include mq('lg', 'max') {
          font-size: 18px;
          padding-top: 8px;
          padding-right: 0px;
          min-width: 201px;
          max-width: 201px;
        }
        @include mq('md', 'max') {
          font-size: 14px;
          min-width: 150px;
          max-width: 150px;
        }
        @include mq('sm', 'max') {
          min-width: 100%;
          max-width: 100%;
          margin-bottom: 10px;
          padding-top: 0;
          line-height: 10px;
        }
      }
      .custom-radio-wrapper {
        display: flex;
        border-left: 1px solid #979797;
        flex-wrap: wrap;
        width: 100%;
        @include mq('lg', 'max') {
          margin-left: 24px;
        }
        @include mq('md', 'max') {
          margin-left: 18px;
        }
        @include mq('sm', 'max') {
          margin-left: 0;
        }
        .custom-radio {
          width: 50%;
        }
      }
      button {
        border: 0;
        background: transparent;
        overflow: hidden;
        width: 20px;
        height: 27px;
        padding: 0;
        color: transparent;
        position: absolute;
        top: auto;
        right: 10px;
        cursor: pointer;
        @include mq('lg', 'max') {
          top: 7px;
          right: 7px;
        }
        &:after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          background-image: url('./../../assets/images/arrows-circle.svg');
          width: 20px;
          height: 27px;
          display: inline-flex;
          background-size: 20px;
          background-repeat: no-repeat;
          @include mq('lg', 'max') {
            width: 12px;
            height: 23px;
            background-size: 12px;
          }
        }
        &.disable-btn {
          &:after {
            opacity: 0.3;
          }
        }
      }
      .form-group {
        display: flex;
        align-items: center;
        padding: 14px 40px 14px 23px;
        border-bottom: 1px solid var(--color-border);
        position: relative;
        @include mq('xxl', 'max') {
          padding: 11px 40px 11px 23px;
        }
        @include mq('lg', 'max') {
          padding: 7px 17px;
          align-items: flex-start;
        }
        @include mq('sm', 'max') {
          flex-direction: column;
          padding: 10px 15px;
        }
      }
    }
    .reset-download {
      margin: 26px 0 0;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      position: relative;
      @include mq('xxl', 'max') {
        margin: 20px 0 0;
      }
      @include mq('lg', 'max') {
        margin: 18px 0 0;
      }
      @include mq('md', 'max') {
        margin: 10px 0 0;
        align-items: flex-start;
      }
      .reser-wrapper {
        a {
          display: flex;
          align-items: center;
          img {
            margin-right: 16px;
            min-width: 27px;
            @include mq('xxl', 'max') {
              margin-right: 12px;
              min-width: auto;
            }
            @include mq('md', 'max') {
              margin-right: 8px;
              max-width: 11px;
            }
          }
          span {
            font-size: 24px;
            @include Font-Medium;
            display: inline-block;
            @include mq('xxl', 'max') {
              font-size: 20px;
            }
            @include mq('lg', 'max') {
              font-size: 21px;
            }
            @include mq('md', 'max') {
              font-size: 14px;
            }
          }
        }
      }
      .download-wrapper {
        position: relative;
        .download-btn {
          display: inline-flex;
          align-items: center;
          justify-content: center;
          font-size: 22px;
          @include Font-Bold;
          color: var(--color-primary);
          min-width: 247px;
          padding: 25px 15px 24px;
          outline: 0;
          cursor: pointer;
          border: 2px solid var(--color-primary);
          border-radius: 8px;
          background-size: 16px 9px;
          @include mq('xxl', 'max') {
            font-size: 18px;
            min-width: 185px;
            padding: 18px 15px 17px;
          }
          @include mq('lg', 'max') {
            padding: 17px 15px 16px;
          }
          @include mq('md', 'max') {
            border: 1px solid var(--color-primary);
            min-width: 112px;
            font-size: 12px;
            border-radius: 4px;
            padding: 9px 15px;
          }
          img {
            margin-left: 19px;
            transition: all 0.2s;
            @include mq('xxl', 'max') {
              margin-left: 10px;
            }
            @include mq('md', 'max') {
              max-width: 8px;
              margin-left: 8px;
            }
          }
          &.open {
            img {
              transform: rotate(180deg);
            }
          }
        }

        .dropdown {
          border: 1px solid var(--color-graydark);
          border-radius: 6px;
          box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.2);
          margin-top: 10px;
          overflow: hidden;
          position: absolute;
          width: 100%;
          @include mq('md', 'max') {
            margin-top: 4px;
          }
          li {
            a {
              font-size: 22px;
              @include Font-Medium;
              padding: 19px 24px 18px;
              display: block;
              transition: all 0.3s;
              color: var(--color-gray);
              @include mq('xxl', 'max') {
                font-size: 18px;
                padding: 14px 24px;
              }
              @include mq('md', 'max') {
                font-size: 12px;
                padding: 10px 11px 9px;
              }
              &:hover {
                background-color: var(--color-border);
                color: var(--color-black);
              }
            }
            &:not(:last-child) {
              a {
                border-bottom: 1px solid rgba(151, 151, 151, 0.3);
              }
            }
          }
        }
      }
    }
  }
}
.download_error {
  position: absolute;
  right: 0;
  top: -17px;
  font-size: 14px;
  color: red;
}
